import React, { useState } from "react";

import { graphql, useStaticQuery, Link } from "gatsby";
import { QuestionCircleOutlined, AddDependentInput, Button, Checkbox, Container, MasterPage, TextInput, Select, WomanOutlined, ManOutlined, SelectOption, UserAddOutlined, UsergroupAddOutlined, HomeOutlined, EnvironmentOutlined, UserOutlined } from "../../components";
import { Popover } from "antd";
import css from "./style.module.scss";
import axios from "axios";

import _ from "lodash"

const handleInputChange = event => {
    const target = event.target
    const value = target.value
    return value;
}


const subsidies = {
    "1": 12880,
    "2": 17420,
    "3": 21960,
    "4": 26500,
    "5": 31040,
    "6": 35580,
    "7": 40120,
    "8": 44660
}


export default (props) => {

    const [gender, setGender] = useState(props.gender)
    const [age, setAge] = useState(props.age)
    const [email, setEmail] = useState(props.email)
    const [zip_code, setZipCode] = useState(props.zip_code)
    const [income, setIncome] = useState(props.income)
    const [county, setCounty] = useState(props.county)
    const [avaiableCounties, setAvaiableCounties] = useState(props.avaiableCounties)
    const [householdMembers, setHoulseholdMembers] = useState(props.householdMembers || [])
    const [spouse, setSpouse] = useState(props.spouse);
    const [tobacco, setTobacco] = useState(!!props.tobacco);
    const [subsidiesActive, setSubsidiesActive] = useState(!!props.subsidiesActive);

    const [spousePopupState, setSpousePopupState] = useState({
        hovered: false,
        clicked: false,
    });


    const [dependentsPopupState, setDependentsPopupVisible] = useState({
        current: null,
        hovered: false,
        clicked: false,
    });


    function addDependant(dependent) {
        setDependentsPopupVisible({
            current: null,
            hovered: false,
            clicked: false,
        })
        setHoulseholdMembers([...householdMembers.map((h, index) => ({ ...h, open: false, index: Number(index) })), {
            index: householdMembers.length,
            ...dependent
        }]);
    }

    function updateDependant(dependent, index) {
        setDependentsPopupVisible({
            current: null,
            hovered: false,
            clicked: false,
        })


        if (_.isEmpty(dependent)) {
            setHoulseholdMembers(householdMembers.filter((d, i) => { return i !== index }));
        } else {
            setHoulseholdMembers(householdMembers.map((h, i) => {
                return i === index ? { ...h, ...dependent } : { ...h }
            }
            ));
        }

    }

    function addSpouse(spouse) {
        setSpousePopupState({
            clicked: false,
            hovered: false,
        })
        setSpouse(spouse)
    }

    const handleZipCodeChange = async (event) => {
        setZipCode(handleInputChange(event))
        if (!(event.target && event.target.value && event.target.value.length === 5)) {
            setAvaiableCounties([]);
            setCounty(null);
            return;
        }

        const response = await axios({
            method: "get",
            url: `https://api.qa.apolloquotes.com/check-zip-code/${event.target.value}`,
        })


        if (response.status == 200 && response.data.status != "400" && response.data.counties.length > 0) {

            const { counties } = response.data;
            setCounty(counties[0])
            setAvaiableCounties(counties)
        } else {
            setAvaiableCounties([])
            setCounty(null);
        }

    }

    const handleHoverChange = visible => {
        console.log("hover")
        setSpousePopupState({
            hovered: visible,
            clicked: spousePopupState.clicked,
        });
    };

    const handleClickChange = visible => {
        console.log("clicked", visible)
        setSpousePopupState({
            clicked: !spousePopupState.clicked,
            hovered: false,
        });
    };


    const handleDependentHoverChange = (item, visible) => {
        setDependentsPopupVisible({
            current: item,
            hovered: visible,
            clicked: dependentsPopupState.clicked,
        })
    }


    const handleDependentClickChange = (item) => {
        setDependentsPopupVisible({
            current: item,
            hovered: false,
            clicked: !dependentsPopupState.clicked,
        })
    }


    const getMinimumForSubsidies = () => {
        return subsidies[(1 + householdMembers.length + (spouse ? 1 : 0)).toString()]
    }

    return <Container className={css.form + " " + props.className}>

        <Container className={css.inputs}>

            <Container className={css.householdTitle}>
                Location
                <EnvironmentOutlined />
            </Container>
            <TextInput focusedlabel="ZIP CODE" value={zip_code} placeholder="Zip Code" pattern="[0-9]*" onChange={(event) => {
                handleZipCodeChange(event);
            }} />
            {
                avaiableCounties && avaiableCounties.length > 0 && <Select className="fullwidth" size="large" options={
                    avaiableCounties.map(a => {
                        return {
                            label: a.name,
                            value: a.name,
                        }
                    })}

                    value={county ? county.name : null}

                    defaultValue={avaiableCounties[0].name}

                    onChange={(v) => {
                        setCounty(avaiableCounties.filter(f => f.name == v)[0])
                    }}

                />
            }
            <Container>
                <br />

                <Container className={css.householdTitle}>
                    Profile
                  <UserOutlined />
                </Container>
            </Container>
            <Container className={css.inputContainer}>
                <TextInput focusedlabel="EMAIL" value={email} onChange={(event) => { setEmail(handleInputChange(event)) }} type="email" placeholder="Email (Optional)" />
                <Container className="flex1">

                    <Select placeholder="Gender" size="large" value={gender} allowClear showArrow={!gender} onChange={setGender} dropdownStyle={{ background: "#fff" }}>
                        <SelectOption className={`${css.bgWhite}`} value="Male">
                            <Container className={`${css.genderInputMaleContainer} ${gender === "Male" ? css.maleActive : ""}`}>
                                Male
                    <ManOutlined />
                            </Container>
                        </SelectOption>
                        <SelectOption className={css.bgWhite} value="Female">
                            <Container className={`${css.genderInputFemaleContainer} ${gender === "Female" ? css.femaleActive : ""}`}>
                                Female
                    <WomanOutlined />
                            </Container>
                        </SelectOption>
                    </Select>
                </Container>
            </Container>
            <Container style={{
                display: "flex", marginBottom: "18px"
            }}>
                <TextInput size="large" className="nomargin" addonBefore="AGE" style={{ width: "136px", marginRight: "12px" }} value={age} onChange={(event) => { setAge(handleInputChange(event)) }} type="number" placeholder="years" />
                {
                    age >= 18 && <Container className={css.tobaccoContainer}>
                        <Container className={css.tobaccoAddon}>
                            USES TOBACCO
                    </Container>
                        <Select size="large" value={tobacco} onChange={(e) => { setTobacco(e) }} options={[{
                            label: "NO",
                            value: false,
                        }, {
                            label: "YES",
                            value: true,
                        }

                        ]} />
                    </Container>}
            </Container>


            <Container className={css.addDependent}>


            </Container>
            <Container className={css.householdContainer}>
                <Container className={css.householdTitle}>
                    Household
                  <HomeOutlined />
                </Container>
                <Container className={css.householdMembers}>
                    {
                        householdMembers.map((hm, index) => {
                            return <Popover key={`pop-${index}`}
                                visible={dependentsPopupState.current === index && (dependentsPopupState.hovered || dependentsPopupState.clicked)}
                                onVisibleChange={(visible) => {
                                    handleDependentHoverChange(index, visible)
                                }}
                                trigger="hover" placement="bottom" title={`Dependent ${Number(index + 1)}`} content={<AddDependentInput {...householdMembers[Number(index)]} onSubmit={(d) => { updateDependant(d, index) }} />}>
                                <Container onClick={() => {
                                    handleDependentClickChange(index)
                                }} className={`${css.householdMember} ${spousePopupState.clicked ? css.active : ""}`}>


                                    <Container className={css.householdMemberIcon}>
                                        {householdMembers[Number(index)].gender == "Female" ? <WomanOutlined className={css.femaleActive} /> : <ManOutlined className={css.maleActive} />}
                                    </Container>
                                    <Container>
                                        {`DEPENDENT`}
                                    </Container>
                                    <Container className={css.dependentAgeContainer}>
                                        {householdMembers[Number(index)].age}
                                    </Container>
                                </Container>
                            </Popover>
                        })
                    }
                    <Popover
                        visible={spousePopupState.hovered || spousePopupState.clicked}
                        onVisibleChange={handleHoverChange}
                        trigger="hover" placement="bottom" title="Spouse" content={<AddDependentInput {...spouse} onSubmit={addSpouse} />}>
                        <Container onClick={handleClickChange} className={`${css.householdMember} ${spousePopupState.clicked ? css.active : ""}`}>
                            {
                                spouse ? <>

                                    <Container className={css.householdMemberIcon}>
                                        {spouse.gender == "Female" ? <WomanOutlined className={css.femaleActive} /> : <ManOutlined className={css.maleActive} />}
                                    </Container>
                                    <Container>
                                        SPOUSE
                            </Container>
                                    <Container className={css.dependentAgeContainer}>
                                        {spouse.age}
                                    </Container>
                                </> :

                                    <>
                                        <Container className={css.householdMemberIcon}>
                                            <UserAddOutlined />
                                        </Container>
                                        <Container>ADD SPOUSE</Container>
                                    </>

                            }
                        </Container>
                    </Popover>

                    <Popover
                        visible={dependentsPopupState.current == null && (dependentsPopupState.hovered || dependentsPopupState.clicked)}

                        onVisibleChange={(visible) => {
                            handleDependentHoverChange(null, visible)
                        }}
                        trigger="hover" placement="bottom" title="Dependent" content={<AddDependentInput onSubmit={addDependant} />}>
                        <Container onClick={() => {
                            handleDependentClickChange(null)
                        }} className={`${css.householdMember} ${spousePopupState.clicked ? css.active : ""}`}>


                            <Container className={css.householdMemberIcon}>
                                <UsergroupAddOutlined />
                            </Container>
                            <Container>ADD DEPENDENT</Container>
                        </Container>
                    </Popover>
                </Container>
            </Container >


            <br />

            <TextInput focusedlabel="ANNUAL INCOME" value={income} onChange={(event) => { setIncome(handleInputChange(event)) }} type="number" placeholder="Annual household income" />

        </Container>
        {

            income && income >= getMinimumForSubsidies() && income <= (getMinimumForSubsidies() * 4) &&
            <Container className={css.mayQualify}>
                <Container className={css.qualifyForSubsidies}>
                    You may qualify for subsidies

                  <Container className={css.subsidies}>

                        <Container className={css.qualifyForSubsidies}>
                            <Container className={css.qualifyForSubsidiesText}>
                                <Checkbox value={subsidiesActive} onChange={(e) => { setSubsidiesActive(e.target.checked) }} className={css.subsidiesCheckbox} />
                        Get quote with subsidies
                    </Container>


                        </Container>


                    </Container>

                </Container>


                <Popover

                    placement="top"
                    content={
                        <Container>
                            With the provided income and household information, you might qualify for healthcare subsidies. For more information visit: <a target="_blank" href="https://www.healthcare.gov/glossary/subsidized-coverage/">https://www.healthcare.gov/glossary/subsidized-coverage/</a>
                        </Container>
                    }

                    title="" >
                    <QuestionCircleOutlined className={css.questionIcon} />
                </Popover>
            </Container>
        }

        {county && age && income && gender ? <Link onClick={() => { props.onSubmit && props.onSubmit() }} style={{ alignSelf: "center" }} to={`/quotes?income=${income}&gender=${gender}&age=${age}&email=${email}&zip_code=${zip_code}&fips_code=${county.fips}&state=${county.state}&uses_tobacco=${!!tobacco}&subsidy_eligible=${!!subsidiesActive}${spouse ? "&spouse=" + JSON.stringify(spouse) : ""}&dependents=${householdMembers ? JSON.stringify(householdMembers) : "{}"}`} className="apollo-button-container">
            <Button shape="round" onClick={() => { }} type="primary">
                GET A QUOTE
                </Button>
        </Link> :
            <div style={{ background: "#ccc", alignSelf: "center" }} className="apollo-button-container">
                <Button style={{ background: "#ccc" }} disabled shape="round" onClick={() => { }} >
                    GET A QUOTE
                </Button>
            </div>}

    </Container>
}
