import React from "react";
import { Slider } from "antd"
import MinMaxSlider from "./min-max"
import NumberSlider from "./number"

export {
    MinMaxSlider,
    NumberSlider,
}


export default (props) => <Slider {...props} />