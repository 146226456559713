import React, { useState } from "react";

import { Container, Spinner } from "../../../../"
import { SettingOutlined } from "../../../../"

import css from "./style.module.scss"

import { Switch } from "antd"

import DecisionSupportPopup from "./popups/decision-support-popup"


export default (props) => {

    const [picwellActive, setPicwellSwitchActive] = useState();
    const [popupVisible, setPopupVisible] = useState();
    const [results, setResult] = useState();


    const picwellSwitchChange = (e) => {

        if (e) {
            setPopupVisible(true)
        } else {
            setPicwellSwitchActive(false);
            props.onPicwellOff && props.onPicwellOff();
        }
    }
    return null;

    return <>
        <DecisionSupportPopup onSubmit={() => { setPicwellSwitchActive(true); props.onSubmit() }} visible={popupVisible} onClose={() => setPopupVisible(false)} />
        <hr style={{
            width: "90%"
        }}></hr>
        {       props.enabled &&
            <Container onPress={() => { setPopupVisible(true) }} className={css.decisionTitle}>

                <button className={css.pablations}>Click here for decision support</button>

            </Container>}
        {props.enabled ?
            < Container className={css.decisionSupportText}>
                <img style={{ width: "132px", marginRight: "6px" }} src={"/partners/picwell/picwell-green-2020.png"} alt="Picwell"></img>
                <Switch
                    onChange={picwellSwitchChange}
                    disabled={!props.enabled} style={{ marginTop: "8px" }}
                    checked={picwellActive} />
            </Container> : <Spinner />
        }
        <hr style={{
            marginTop: "18px",
            width: "90%"
        }}></hr>
    </>
}