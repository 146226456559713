import React from "react"

import { Spin } from "antd"

import { Container } from "../"

import css from "./style.module.scss"

export const SpinnerPanel = (props) => <Container className={`${css.spinnerContainer}`}>
    <Spin {...props} />
</Container>

export default Spin;