import React from "react";
import { Accordeon, Container, Badge } from "../../.."
import css from "./style.module.scss";
import FilterGroup from "./filter-group";
import { ApolloCollors } from "../../../rules/design/apollo-colors";
import { v4 as uuid } from 'uuid';




export default (props) => {

    const buildFilters = (items, label) => {

        return <>
            {
                items.map(item => {
                    if (item.type === "GROUP") {
                        return <FilterGroup onChange={(i, v) => { props.onChange && props.onChange(label, i, v) }} key={label + item.type + item.attribute} {...item} />
                    }
                })
            }
        </>
    }

    return <Container className={css.searchFiltersContainer}>
        {props.data.map(facetItem => {
            if (facetItem.type === "GROUP") {
                return <Accordeon key={"group" + facetItem.label} title={facetItem.label}>
                    {
                        buildFilters(facetItem.values, facetItem.label)
                    }
                </Accordeon>
            }
        })}
    </Container>
}