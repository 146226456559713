import React from "react";
import { Container } from "../";
import css from "./style.module.scss";


// ok
export default (props) => <Container style={props.style} className={`${css.badgeContainer} ${props.className}`}>
    <svg viewBox="0 0 100 100" style={{ height: "100%" }}>
        <circle cx="50%" cy="50%" r="50" fill={props.color} />
    </svg>
</Container>