import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid"
import _ from "lodash";

import axios from "axios";
import css from './style.module.scss'
import { Button as AntdButton, Alert, Tabs, Result, Steps } from 'antd';
import { Container, TextInput, QuotesForm } from "../";
import { FilePdfOutlined } from "../"
import { OfferCardList, QuotesPanelProfile, SearchFilters } from "./components"

import datasourcesmock from "./temp/datasources"

import { exportPDF } from "./pdf-util"
import { CheckOutlined } from "../icon";


const handleInputChange = event => {
    const target = event.target
    const value = target.value
    return value;
}

const { TabPane } = Tabs;

const { Step } = Steps;

const steps = [
    {
        title: "Profile"
    },
    {
        title: "Cart"
    },
    {
        title: "Finish",
    }
]

export default (props) => {
    try {
        (function (history: any) {
            var pushState = history.pushState;
            history.pushState = function (state) {
                if (typeof history.onpushstate == "function") {
                    history.onpushstate({ state: state });
                }

                return pushState.apply(history, arguments);
            };
        })(window.history);
    } catch (e) {

    }

    const siteData = props.data;

    let getParams = () => {
        try {
            return document.location.search.substring(1).split("&").reduce((old, current) => { const [key, value] = current.split("="); value && (old[key] = value.replace("%20", " ")); return old }, {});
        } catch (e) {
            return {}
        }
    }

    const agent = {
        id: props.data.site.siteMetadata.agent.id,
        name: props.data.site.siteMetadata.agent.name,
        title: props.data.site.siteMetadata.agent.title,
        email: props.data.site.siteMetadata.agent.email,
        website: props.data.site.siteMetadata.agent.website,
        phone: props.data.site.siteMetadata.agent.phone,
        image: props.data.site.siteMetadata.agent.assets.image
    }


    const [results, setResults]: any[] = useState()
    const [mobileActiveContainer, setMobileActiveContainer] = useState("results")
    const [activeTab, setActiveTab] = useState(`tab-${0}`)
    const [state, setNewState] = useState({ error: false, lastParams: null, loading: true, lastResults: null, params: getParams() });
    const [error, setError] = useState(false);
    const [datasources] = useState(datasourcesmock)
    const [currentState, setCurrentState] = useState(1);
    const [selectedItems, updateSelectedItems] = useState(new Set());
    const [filters, setFilters] = useState({});

    const [name, setName] = useState()

    const [email, setEmail] = useState()

    const [phone, setPhone] = useState()

    const applyFilters = (items, carrier) => {


        const carrierFilters = filters[carrier];

        let filtered = items;

        if (carrierFilters) {

            Object.keys(carrierFilters).forEach(k => {
                if (k == "CHECKBOX") {

                    const attributes = Object.keys(carrierFilters[k])

                    attributes.forEach(a => {
                        const attributeValue = carrierFilters[k][a];

                        if (!attributeValue || attributeValue.size == 0) {
                            return;
                        }

                        const planMap = a.split(".");
                        filtered = items.filter(planValue => {

                            planMap.forEach(planKey => {
                                planValue = planValue[planKey]
                            })

                            return Array.from(attributeValue).includes(planValue)
                        })
                    })
                }
            })
        }

        const generalFilters = filters["ALL"];

        if (generalFilters) {


            Object.keys(generalFilters).forEach(k => {
                if (k == "MIN_MAX_SLIDER") {
                    const attributes = Object.keys(generalFilters[k])

                    attributes.forEach(a => {

                        let minMax = generalFilters[k][a];

                        console.log("minMax", minMax)
                        if (minMax) {
                            const planMap = a.split(".");

                            filtered = filtered.filter(planValue => {


                                console.log("plan value", planValue)
                                planMap.forEach(planKey => {
                                    planValue = planValue[planKey]
                                })

                                console.log("plan value", planValue)
                                console.log("min", minMax)

                                return planValue >= minMax.min && planValue <= minMax.max
                            })

                            console.log("filtered", filtered)

                        }
                    })
                }
            })
        }



        return filtered;

    }

    const onFilterChange = (carrier, item, value) => {

        let carrierKey;

        if (carrier == "General") carrierKey = "ALL";
        else if (carrier == "Marketplace") carrierKey = "MARKETPLACE";
        else if (carrier == "National General") carrierKey = "NATIONAL_GENERAL";
        else if (carrier == "OneShare") carrierKey = "ONE_SHARE";

        if (!filters[carrierKey]) filters[carrierKey] = {}

        if (!filters[carrierKey][item.type]) filters[carrierKey][item.type] = {}



        if (item.type == "CHECKBOX") {
            if (!filters[carrierKey][item.type][item.attribute]) filters[carrierKey][item.type][item.attribute] = new Set()

            const currentSet: Set<String> = filters[carrierKey][item.type][item.attribute];

            if (value.value) {
                currentSet.add(value.label);
            } else {
                currentSet.delete(value.label);
            }

            filters[carrierKey][item.type][item.attribute] = currentSet;


        } else if (item.type == "MIN_MAX_SLIDER") {
            filters[carrierKey][item.type][item.attribute] = {
                min: value[0],
                max: value[1],
            }
        }

        setFilters({ ...filters });
    }



    const onOffercardSelected = (value) => {
        const newSelectedItems = new Set([...selectedItems]);
        value.checked ? newSelectedItems.add(value.id) : newSelectedItems.delete(value.id);
        updateSelectedItems(newSelectedItems);
    }

    const fetchQuotes = async (attributes) => {
        let r = [];

        const propKeys = Object.keys(attributes).filter(f => datasources[1].inputs.map(m => m.name).includes(f))
        let data: any = {};

        propKeys.map(pk => {
            const type = datasources[1].inputs.filter(f => f.name == pk)[0]
            data[pk] = type.type === "number" ? Number(attributes[pk]) : attributes[pk]
            data[pk] = type.type === "object" || type.type === "array" ? JSON.parse(decodeURI(attributes[pk])) : attributes[pk]
        })

        try {
            const response = await axios({
                method: 'post',
                url: `https://api2.myaspirequotes.qa.apolloquotes.com/v1/plans/`,
                data,
                headers: {
                    "X-Apollo-Quotes-Source": props.data.site.siteMetadata.agent.id
                }
            })

            console.log("response", response)

            const MARKETPLACE = response.data.plans[0].MARKETPLACE.map(r => { return { ...r, id: uuid() } });

            const NATIONAL_GENERAL = response.data.plans[1].NATIONAL_GENERAL.map(r => { return { ...r, id: uuid() } });;

            const ONE_SHARE = response.data.plans[2].ONE_SHARE.map(r => { return { ...r, id: uuid() } });;

            const ASPIRE_DENTAL = response.data.plans[3].ASPIRE_DENTAL.map(r => { return { ...r, id: uuid() } });;

            response.data.plans = {
                MARKETPLACE,
                NATIONAL_GENERAL,
                ONE_SHARE,
                ASPIRE_DENTAL,
                ALL: [...MARKETPLACE, ...NATIONAL_GENERAL, ...ONE_SHARE, ...ASPIRE_DENTAL],
                MEDICAL: [...MARKETPLACE, ...NATIONAL_GENERAL, ...ONE_SHARE],
                DENTAL: [...ASPIRE_DENTAL]
            }

            r.push({ ...datasources[0], response })

            return r;
        } catch (error) {
            console.log("error", error)
            setError(true);
            return { error: true }
        }
    }

    try {
        window.onpopstate = (window as any).onpushstate = (e) => {
            setResults();
            setError(false);

            const params = getParams();
            setNewState({
                params,
                loading: true,
                error: false,
                lastParams: state.lastParams,
                lastResults: results
            })
        };
    } catch (e) {

    }

    useEffect(() => {
        const params = document.location.search.substring(1).split("&").reduce((old, current) => { const [key, value] = current.split("="); value && (old[key] = value.replace("%20", " ")); return old }, {})

        fetchQuotes(params).then((r: any) => {
            if (r && r.error) {
                return;
            }
            if (!_.isEqual(r, results)) {
                setResults(
                    r[0].response.data,
                )
            }
        })
    }, [state])


    const sendQuote = async () => {

        const data = {
            user_name: name,
            user_email: email,
            user_phone: phone
        }

        exportPDF(results.plans["ALL"].filter(f => selectedItems.has(f.id)), agent, data, results.uuid);

        setCurrentState(3);
    }

    return (
        <>
            {selectedItems && currentState == 1 && selectedItems.size > 0 && <Container className={css.floatButton}>
                <AntdButton onClick={() => { setCurrentState(2) }} className={css.floatButtonButton} shape="round" >
                    Finish
                    <CheckOutlined />
                </AntdButton>
            </Container>}
            <Container className={css.mainSearchContainer}>
                {
                    currentState == 0 && <Container className={css.profileContainer}>
                        <Container className={`${css.simulatedLeft} hidden-on-mobile`} />

                        <Container className={css.quotesFormContainer}>
                            <Container className={css.stepsContainer}>
                                <Steps size="small" className={css.steps} current={
                                    currentState
                                }
                                    type="navigation"
                                    onChange={(e) => { setCurrentState(e) }}
                                >
                                    {steps.map((item, pos) => (
                                        <Step key={"step" + pos + "an"} disabled={(pos == 1 && !results) || (pos == 2 && selectedItems.size == 0)} title={item.title} />
                                    ))}
                                </Steps>
                            </Container>
                            <Container className={css.quotesFormInner}>
                                <QuotesForm {...getParams()} onSubmit={() => {
                                    setResults();
                                    setError(false);
                                    setCurrentState(1);
                                    updateSelectedItems(new Set())
                                    const params = getParams();
                                    setNewState({
                                        params,
                                        loading: true,
                                        error: false,
                                        lastParams: state.lastParams,
                                        lastResults: results
                                    })
                                }} />
                            </Container>
                        </Container>
                    </Container>
                }
                {
                    !error && currentState == 1 && <Container className={`${mobileActiveContainer !== "filters" ? "hidden-on-mobile" : ""}`}>
                        {results && <SearchFilters onChange={onFilterChange} data={results.facets} />}
                    </Container>
                }



                {
                    currentState == 1 && <Container className={`${css.cardListContainer} ${mobileActiveContainer !== "results" ? "hidden-on-mobile" : ""}`}>
                        <Container className={css.stepsContainer}>
                            <Steps size="small" className={css.steps} current={
                                currentState
                            }
                                type="navigation"
                                onChange={(e) => { setCurrentState(e) }}
                            >
                                {steps.map((item, pos) => (
                                    <Step key={"step" + pos + "a"} status={pos == 1 && currentState == 1 && error ? "error" : undefined} disabled={pos == 2 && selectedItems.size == 0} title={item.title} />
                                ))}
                            </Steps>
                        </Container>
                        {
                            error &&
                            <Container className={css.errorCardContainer}>
                                <Alert
                                    message="Ops, there was an error"
                                    showIcon
                                    description="Please try again in a second and if the error persist change the data"
                                    type="error"
                                    className={css.errorCard}

                                /></Container>
                        }
                        {!error &&
                            <Tabs tabBarStyle={{ background: "#fff", maxWidth: "100vw", paddingLeft: "2vw" }} onChange={setActiveTab} activeKey={activeTab} centered  >
                                <TabPane tab={`Marketplace ${results ? "(" + applyFilters(results.plans["MARKETPLACE"], "MARKETPLACE").length + ")" : ""}`} key={`tab-${"0"}`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_cod
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["MARKETPLACE"], "MARKETPLACE").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`National General ${results ? "(" + applyFilters(results.plans["NATIONAL_GENERAL"], "NATIONAL_GENERAL").length + ")" : ""}`} key={`tab-${"1"}`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["NATIONAL_GENERAL"], "NATIONAL_GENERAL").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`One Share ${results ? "(" + applyFilters(results.plans["ONE_SHARE"], "ONE_SHARE").length + ")" : ""}`} key={`tab-${"2"}`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["ONE_SHARE"], "ONE_SHARE").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                                <TabPane tab={`Aspire Dental ${results ? "(" + applyFilters(results.plans["ASPIRE_DENTAL"], "ASPIRE_DENTAL").length + ")" : ""}`} key={`tab-${"3"}`}>
                                    {
                                        <OfferCardList data={siteData} key={uuid()} loading={!results} userData={
                                            [
                                                {
                                                    label: "age",
                                                    value: props.age
                                                },
                                                {
                                                    label: "zip code",
                                                    value: props.zip_code
                                                }
                                            ]
                                        } items={results ? applyFilters(results.plans["ASPIRE_DENTAL"], "ASPIRE_DENTAL").map((r) => ({
                                            ...r,
                                            selected: selectedItems.has(r.id),
                                            onSelectChange: onOffercardSelected
                                        })) : []} />
                                    }
                                </TabPane>
                            </Tabs>
                        }
                    </Container>
                }
                {
                    currentState == 2 && <Container className={css.profileContainer}>
                        <Container className={`${css.simulatedLeft} hidden-on-mobile`} />

                        <Container className={css.quotesFormContainer}>
                            <Container className={css.stepsContainer}>
                                <Steps size="small" className={css.steps} current={
                                    currentState
                                }
                                    type="navigation"
                                    onChange={(e) => { setCurrentState(e) }}
                                >
                                    {steps.map((item, pos) => (
                                        <Step key={"stepsz" + pos} title={item.title} />
                                    ))}
                                </Steps>
                            </Container>
                            <Container className={css.thirdStep}>
                                <Container className={css.toEnroll}>
                                    To enroll in these plans fill out your details and we will contact you to complete the process
                                </Container>
                                <Container className={css.finalFormContainer}>
                                    <Container className={css.finalFormSummary}>
                                        <Container className={css.planTypeContainer}>
                                            <Container className={css.planType}>Medical</Container>
                                            {
                                                [...selectedItems].map(s => {
                                                    const plan = results.plans["MEDICAL"].filter(f => f.id == "" + s)[0];
                                                    if (!plan) {
                                                        return null;
                                                    }
                                                    return <Container className={css.planData}>
                                                        <Container className={css.planName} key={"f" + plan.id}>{plan.plan_name.value}</Container>
                                                        <Container className={css.premium} key={"f" + plan.id}>${plan.premium.value}</Container>
                                                    </Container>
                                                })
                                            }
                                        </Container>
                                        <Container style={{ marginBottom: "12px" }} className={css.planTypeContainer}>
                                            <Container className={css.planType}>Dental</Container>
                                            {
                                                [...selectedItems].map(s => {
                                                    const plan = results.plans["DENTAL"].filter(f => f.id == "" + s)[0];
                                                    if (!plan) {
                                                        return null;
                                                    }
                                                    return <Container className={css.planData}>
                                                        <Container className={css.planName} key={"f" + plan.id}>{plan.plan_name.value}</Container>
                                                        <Container className={css.premium} key={"f" + plan.id}>${plan.premium.value}</Container>
                                                    </Container>
                                                })
                                            }
                                        </Container>
                                        <Container style={{ position: "absolute", bottom: "0px", width: "100%" }} className={css.planTypeContainer}>
                                            <Container style={{ marginTop: "12px" }} className={css.planData}>
                                                <Container className={css.planName}>Total premium</Container>
                                                <Container className={css.premium}>${results.plans["ALL"].filter(f => selectedItems.has(f.id)).reduce((a, b) => { return a + b.premium.value }, 0)}</Container>
                                            </Container>
                                        </Container>
                                    </Container>
                                    <Container className={css.finalForm}>
                                        <Container className={css.finalFormInputs}>
                                            <TextInput value={name} onChange={(e) => { setName(handleInputChange(e)) }} className={css.finalFormInputText} containerclassname={css.finalFormInputText} placeholder="name" />
                                            <TextInput value={email} onChange={(e) => { setEmail(handleInputChange(e)) }} className={css.finalFormInputText} containerclassname={css.finalFormInputText} placeholder="email" />
                                            <TextInput value={phone} onChange={(e) => { setPhone(handleInputChange(e)) }} className={css.finalFormInputText} containerclassname={css.finalFormInputText} placeholder="phone" />
                                        </Container>
                                        <AntdButton disabled={!name || !email || !phone} onClick={sendQuote} type="primary" className={css.sendButton}>
                                            SEND
                                    </AntdButton>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                }
                {
                    currentState == 3 && <Container className={css.profileContainer}>
                        <Container className={`${css.simulatedLeft} hidden-on-mobile`} />
                        <Container className={css.quotesFormContainer}>
                            <Result
                                status="success"
                                title="Thank you for submitting a request with ApolloQuotes!"
                                subTitle="Please allow your Apollo agent time to review your request to follow up with more details about the right plan for you."
                                extra={[
                                    <AntdButton onClick={() => { exportPDF(results.plans["ALL"].filter(f => selectedItems.has(f.id)), agent) }} danger type="primary" key="downloadpdf">
                                        Download PDF
                                        <FilePdfOutlined />
                                    </AntdButton>,
                                    <AntdButton onClick={
                                        () => {
                                            setCurrentState(0);
                                        }
                                    } type="primary" key="newquote">Quote again</AntdButton>,
                                ]}
                            />

                        </Container>
                    </Container>
                }
                <QuotesPanelProfile enabled={results} onPicwellOn={() => {
                    const tempR = { ...results };

                    tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map((res, index) => {
                        return { ...res, score: (100 / tempR.plans.MARKETPLACE.length) * (index + 1) }
                    })
                    setResults(
                        tempR
                    )
                }

                }

                    onPicwellOff={() => {
                        const tempR = { ...results };

                        tempR.plans.MARKETPLACE = tempR.plans.MARKETPLACE.map((res, index) => {
                            return { ...res, score: null }
                        })

                        setResults(
                            tempR
                        )
                    }}

                    data={siteData} mobileActiveContainer={mobileActiveContainer} />
            </Container>
            <Container id="pdf" />

        </>
    )
}

export {
    OfferCardList,
    SearchFilters
}