import React from "react"
import css from "./style.module.scss";

import { Button } from "antd";
import { Container } from "..";

export default (props) => {
    return <>
        <Button className={`${css.apolloButton} ${props.disabled ? css.disabled : ""}`} {...props} />
    </>
}