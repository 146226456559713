import React, { useState } from "react"
import css from "./style.module.scss";

import { Input } from "antd";

import { Container } from ".."

export default (props) => {
    const [focused, setFocused] = useState(false)
    return <Container className={`${css.relative} ${props.containerclassname}`}>
        <Input onFocus={() => { setFocused(true) }} onBlur={() => { setFocused(false) }} className={css.apolloInput} {...props} />
        {(focused || (props.value && props.value != "")) && <Container className={css.placeholder}>
            {props.focusedlabel}
        </Container>
        }
    </ Container >
}