import React, { useState } from "react";
import { Checkbox, Container, Select, SelectOption, ManOutlined, WomanOutlined, TextInput } from "../../";
import { Button as AntdButton } from "antd"

import { handleInputChange } from "../../../utils"

import css from "./style.module.scss";
import { CheckOutlined, CloseOutlined, EditFilled, EditOutlined, EditTwoTone, SendOutlined } from "../../icon";

export default (props) => {

    const [age, setAge] = useState(props.age);
    const [gender, setGender] = useState(props.gender);
    const [tobacco, setTobacco] = useState(props.tobacco);


    return <Container>
        <Container className={css.inputContainer}>
            <Container className="flex1">
                <Select placeholder="Gender" size="large" value={gender} allowClear showArrow={!gender} onChange={setGender} dropdownStyle={{ background: "#fff" }}>
                    <SelectOption className={`${css.bgWhite}`} value="Male">
                        <Container className={`${css.genderInputMaleContainer} ${gender === "Male" ? css.maleActive : ""}`}>
                            Male
                            <ManOutlined />
                        </Container>

                    </SelectOption>
                    <SelectOption className={css.bgWhite} value="Female">
                        <Container className={`${css.genderInputFemaleContainer} ${gender === "Female" ? css.femaleActive : ""}`}>
                            Female
                            <WomanOutlined />
                        </Container>
                    </SelectOption>
                </Select>
            </Container>
        </Container>
        <TextInput value={age} onChange={(event) => { setAge(handleInputChange(event)) }} type="number" placeholder="Age" />
        {
            age >= 18 && <Container className={css.tobaccoContainer}>
                <Checkbox value={tobacco} onChange={(e) => { setTobacco(e.target.checked) }} className={css.tobaccoCheckbox} />
                Uses tobacco
            </Container>
        }
        <Container className="centered" style={{ marginTop: "12px" }}>
            {
                props.gender ?
                    <Container style={{ display: "flex" }}>
                        <AntdButton className="grid-margin-right" danger type="primary" onClick={() => { props.onSubmit && props.onSubmit(null) }}>
                            REMOVE
                            <CloseOutlined />
                        </AntdButton>
                        <AntdButton className="grid-margin-left" type="primary" onClick={() => { props.onSubmit && props.onSubmit({ age, gender }) }}>
                            UPDATE
                            <CheckOutlined />
                        </AntdButton>
                    </Container>
                    :
                    <AntdButton className="flex1" type="primary" onClick={() => { props.onSubmit && props.onSubmit({ age, gender }) }}>
                        ADD
            </AntdButton>
            }
        </Container>
    </Container >
}