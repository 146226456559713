import React from "react"
import { Container, Logo, Menu } from "../../components"
import { Link, useStaticQuery, graphql } from "gatsby"
import css from "./style.module.scss"
import QuoteMobileIcon from "../../assets/quote-mobile.svg"
import QuoteWebIcon from "../../assets/quote-web.svg"



const links = [
  {
    name: "About",
    url: "/"
  },
  {
    name: "Help",
    url: "/"
  },
]

const Header = (props) => {
  const { data } = props;

  return (
    <header className={css.mainContainer}>

      <Container className={css.logoContainer}>

        <Logo />
      </Container>

      <Container className={css.agentName}>
        {data && data.site.siteMetadata.agent.name}
      </Container>
      <Container className={css.headerRight}>

        <Container className={css.menuContainer}>

          {          //<QuoteMobileIcon className={css.quoteMobileIcon} />
            //<QuoteWebIcon className={css.quoteWebIcon} />
          }

        </Container>
      </Container>
    </header>
  )
}

export default Header
